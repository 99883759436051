export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyASu684g9z8SsTsFFFXVIbZyTnXcXHZEhc",
  authDomain: "app.test.bookcreator.com",
  databaseURL: "https://bookcreator-test.firebaseio.com",
  storageBucket: "bookcreator-test.appspot.com",
  messagingSenderId: "925484038468",
  projectName: "bookcreator-test",
  projectId: "bookcreator-test",
};
export const ADMIN_URL = "https://admin.test.bookcreator.com";
export const BOOKCREATOR_APP_URL = "https://app.test.bookcreator.com";
export const BOOKCREATOR_API_URL = "https://api.test.bookcreator.com";
export const BOOKCREATOR_ORGS_API_URL = "https://organisations.api.test.bookcreator.com";
export const ANALYTICS_DOMAIN = "https://analytics.api.test.bookcreator.com";

export const BOOKCREATOR_ASSETS_URL = "https://assets.api.test.bookcreator.com";
export const BOOKCREATOR_STORAGE_URL = "https://storage.test.bookcreator.com";
export const BOOKCREATOR_PROMO_CODE_PREFIX =
  "bookcreator.test://";
export const SUBSCRIPTIONS_DOMAIN = "https://subscriptions.api.test.bookcreator.com";
export const FIREBASE_DATABASE_APPS_URL =
  "htts://bookcreator-test-apps.firebaseio.com";
export const FIREBASE_DATABASE_BLOCKS_URL =
  "https://bookcreator-test-blocks.firebaseio.com";
export const FIREBASE_DATABASE_DELETED_ORGS_URL =
  "https://bookcreator-test-closed-organisations.europe-west1.firebasedatabase.app/";
export const NOUN_PROJECT_DOMAIN = "https://noun.api.test.bookcreator.com";
export const SEARCH_API_DOMAIN = "https://search.api.test.bookcreator.com";

export const GA_ID = "";

export const SENTRY_URL = "";

export const AUTH_COOKIE_NAME = "mission_bc_auth-test";
export const APP_AUTH_COOKIE_NAME = "mission_bc_auth-test";
export const AUTH_COOKIE_DOMAIN = "test.bookcreator.com";

export const RECURLY_URL = "https://bookcreator-test.recurly.com/subscribe/";
